.UserAccount {
  width: 263px;
  height: 56px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.FormSection {
  border-radius: 10px;
  background-color: rgba(10, 12, 40, 0.5);
}

.AccountImage {
  width: 320px;
  height: auto;
}

.AccountContainer {
  margin-bottom: 15px;
}

.EmailVerify {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
}

.Resend {
  cursor: pointer;
}

.FormLabel {
  color: #ffffff;
}
