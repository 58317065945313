.Modalli {
  color: #000000;
  list-style: none;
}

.Header {
  color: #000000;
}

.ModalTitle {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
