.Tab {
  height: 74px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 14px;
  &:hover {
    background-color: #363857;
  }
}

.PaymentIcon {
  margin-top: 7px;
  margin-left: 14px;
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.TextBold {
  width: 74px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Text {
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
