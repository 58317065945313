.progress {
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.remain {
  stroke-linecap: round;
  stroke: #ebedf8;
  stroke-dashoffset: 142 0;
}
