.StartCard {
  // width: 493px;
  // height: 183px;
  border-radius: 20px;
  background-image: linear-gradient(281deg, #96e6a1, #d4fc79);
}

.StartCardSquar {
  // width: 392px;
  //height: 314px;
  border-radius: 20px;
  background-image: linear-gradient(292deg, #96e6a1, #d4fc79);
}

.AssesLogo {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.TakeAssesText {
  width: 289px;
  height: 48px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #171941;
}

.StartWrapper {
  margin: 12px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.StartAssesButton {
  height: 48px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  background-color: #007168;
  &:hover {
    background-color: #00a194;
  }
}

.Arrow {
  width: 22px;
  height: 18px;
  object-fit: contain;
}
