.ResultSegment {
  margin: 14px;
  width: 1205px;
  height: 569px;
  border-radius: 10px;
}

.AssesmentsRow {
  margin: 8px;
  min-height: 74px;
  border-radius: 10px;
  background-color: #0a0c28;
}

.Category {
  margin: 24px;
  width: 92px;
  height: 28px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d5e1e6;
}

.StatusRow {
  display: flex;
  justify-content: space-evenly;
}

.Yes {
  text-align: center;
  width: 40px;
  height: 30px;
  border-radius: 4px;
  background-image: linear-gradient(291deg, #96e6a1 95%, #d4fc79 5%);
}

.No {
  text-align: center;
  width: 40px;
  height: 30px;
  border-radius: 4px;
  background-image: linear-gradient(307deg, #fe5196, #f77062);
}

.Pending {
  text-align: center;
  width: 40px;
  height: 30px;
  border-radius: 4px;
  background-image: linear-gradient(to left, #8488d2, #9599e2 0%);
}

.StatusText {
  margin-top: 3px;
  margin-left: 14px;
  width: 25px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.StatusNumber {
  margin-top: 5px;
  width: 13px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #007168;
}

.Arrow {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.QuestionRow {
  margin: 8px;
  min-height: 76px;
  border-radius: 10px;
  border: solid 1px #9497c0;
}

.Question {
  word-wrap: break-word;
  margin-left: 28px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.QuestionYes {
  margin: auto;
  text-align: center;
  border-radius: 4px;
  background-image: linear-gradient(300deg, #00ff22, #aeff00);
}

.QuestionNo {
  margin: auto;
  text-align: center;
  border-radius: 4px;
  background-image: linear-gradient(300deg, #fe5196, #f77062);
}

.QuestionPending {
  margin: auto;
  text-align: center;
  border-radius: 4px;
  background-image: linear-gradient(300deg, #fed651, #ff9215);
}

.QuestionNumber {
  display: inline-block;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.MoreInfo {
  cursor: pointer;
  width: 696px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c61f91;
  &:hover {
    color: #fa29b8;
  }
}

.Model {
  background-color: #0a0c28;
}

.ModelBackground {
  background-color: rgba(23, 25, 65, 0.85);
}

.MoreQuestion {
  width: 649px;
  height: 66px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #96e6a1;
}

.Why {
  width: 655px;
  height: 19px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9497c0;
}

.Answer {
  width: 649px;
  height: 38px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.GoIt {
  float: right;
}

.Finish {
  cursor: pointer;
  margin-right: 7px;
  float: right;
  width: 119px;
  height: 40px;
  border-radius: 6px;
  border: none;
  background-image: linear-gradient(277deg, #96e6a1, #d4fc79);
  &:hover {
    background-image: linear-gradient(277deg, #d4fc79, #96e6a1);
  }
}

@media screen and (max-width: 580px) {
  .Category {
    margin-left: 1px;
    font-size: 14px;
  }
  .Yes {
    width: 30px;
    height: 25px;
  }
  .No {
    width: 30px;
    height: 25px;
  }

  .Pending {
    width: 30px;
    height: 25px;
  }

  .StatusNumber {
    font-size: 14px;
  }
  .StatusText {
    font-size: 12px;
  }

  .StatusRow {
    margin-left: -15px;
  }

  .Arrow {
    width: 24px;
    height: 24px;
    margin-left: -14px;
  }
}
