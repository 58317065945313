.Welcome_to_AIRR {
  width: 342px;
  height: 56px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Welcome_to_AIRR .text_style_1 {
  font-weight: bold;
}

.Start_an_Assessment {
  width: 573px;
  height: 56px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
}

.Dont_have_an_account {
  width: 194px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Signup {
  width: 58px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c61f91;
}

.Rectangle {
  width: 119px;
  height: 48px;
  background-image: linear-gradient(282deg, #96e6a1 98%, #d4fc79 2%);
}

.Demo_2 {
  width: 697px;
}

.mesh {
  width: 434px;
  height: 450px;
  opacity: 0.07;
}

.Forgot_password {
  width: 135px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c61f91;
}

.Submit {
  background-image: linear-gradient(282deg, #96e6a1, #d4fc79);
}

.TopMargin {
  height: 40px;
}

.ButtonStyle {
  width: 119px;
  height: 40px;
  border-radius: 6px;
  border: none;
  background-image: linear-gradient(277deg, #96e6a1, #d4fc79);
  &:hover {
    background-image: linear-gradient(277deg, #d4fc79, #96e6a1);
  }
}

.Arrow {
  width: 20px;
  height: 16px;
  object-fit: contain;
}
