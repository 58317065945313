.AssementCard {
  cursor: pointer;
  height: 265px;
  border-radius: 15px;
  margin: 15px 0px 15px 0px;
}

.AssementResultCard {
  border-radius: 15px;
  margin: 5px;
}

.Ready {
  width: 74px;
  height: 25px;
  margin-right: -15px;
  border-radius: 12px 0 0 12px;
  float: right;
  right: 0px;
}

.Complete {
  width: 30px;
  height: 30px;
}

.Read_Text {
  margin: 10px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Text {
  word-wrap: break-word;
  height: 68px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

.Date {
  width: 127px;
  height: 15px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Line {
  height: 1px;
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.ShowMoreButton {
  width: 80px;
  height: 79px;
  border-radius: 39.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #22e1ff, #1d8fe1, #255696);
}

.ShowMoreIcon {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.Category {
  float: left;
  width: 61px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.CategoryQuestions {
  float: right;
  width: 13px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

@media (max-width: 500px) {
  .AssementCard {
    height: 300px;
    width: 250px;
    margin: 0 auto 15px auto;
  }
}
