.HeaderText {
  width: 238px;
  height: 56px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
