.QnASegment {
  width: 1205px;
  margin: 14px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0a0c28;
}

.QuestionTextWrapper {
  min-height: 170px;
  max-height: 200px;
}

.QuestionText {
  max-height: 150px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
}

.MoreInformatio {
  margin: 5px 0px 15px 0px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c61f91;
  &:hover {
    color: #ff3dc2;
  }
}

.ButtonGroup {
  display: flex;
  justify-content: space-evenly;
}

.Yes {
  width: 188px;
  height: 60px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #007168;
  border-radius: 10px;
  cursor: pointer;
  background-image: linear-gradient(to left, #96e6a0, #d3fc7a);
  &:hover {
    background-image: linear-gradient(to left, #4ce961, #b2f523);
  }
}

.AnswerYes {
  width: 188px;
  height: 60px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #0b7818;
}

.No {
  width: 188px;
  height: 60px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #6b1b38da;
  border-radius: 10px;
  cursor: pointer;
  background-image: linear-gradient(to left, #f77063, #fe5196);
  &:hover {
    background-image: linear-gradient(to left, #fc4535, #ff297e);
  }
}

.AnswerNo {
  width: 188px;
  height: 60px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #9f0b46;
}

.InProgress {
  width: 188px;
  height: 60px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #0a0c28ce;
  border-radius: 10px;
  cursor: pointer;
  background-image: linear-gradient(to left, #565cce, #9599e2);
  &:hover {
    background-image: linear-gradient(to left, #2f349e, #5c63e6);
  }
}

.AnswerInProgress {
  width: 188px;
  height: 60px;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #222678;
}

.NavigateSection {
  display: flex;
  justify-content: space-evenly;
}

.Draft {
  width: 120px;
  height: 48px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 10px;
  color: #9497c0;
  border: solid 1px #9497c0;
  &:hover {
    color: #ffffff;
    border: solid 1px #ffffff;
  }
}

.Result {
  float: right;
  width: 120px;
  height: 48px;
  opacity: 0.8;
  border-radius: 10px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 10px;
  color: #9497c0;
  border: solid 1px #9497c0;
  &:hover {
    color: #96e6a1;
    border: solid 1px #96e6a1;
  }
}

.ResultActive {
  float: right;
  width: 120px;
  height: 48px;
  border-radius: 10px;
  background-color: rgba(9, 255, 46, 0.432);
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 10px;
  color: #ffffff;
  border: solid 1px rgb(255, 255, 255);
  &:hover {
    border: solid 1px #4de061;
  }
}

.Arrow {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.CategorySection {
  background-color: #363857;
}

.Category {
  padding-top: 7px;
  border: none;
  border-radius: 12px 0 0 12px;
  height: 44px;
  &:hover {
    background-color: #171941;
  }
}

.CategoryText {
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: inline-block;
}

.Pending {
  width: 10px;
  height: 10px;
  margin: 5px 0 0 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #c61f91;
}

.QuestionNo {
  padding-top: 3px;
  width: 35px;
  height: 29px;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  float: right;
  background-color: #2a2c4f;
}

.Model {
  background-color: #0a0c28;
}

.ModelBackground {
  background-color: rgba(23, 25, 65, 0.85);
}

.MoreQuestion {
  width: 649px;
  height: 66px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #96e6a1;
}

.Why {
  width: 655px;
  height: 19px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9497c0;
}

.Answer {
  width: 649px;
  height: 38px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.MoreInfo {
  white-space: pre-wrap;
}

.GoIt {
  float: right;
}

@media screen and (max-width: 580px) {
  .Yes {
    width: 100px;
    height: 40px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  .No {
    width: 100px;
    height: 40px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  .InProgress {
    width: 100px;
    height: 40px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  .QuestionNo {
    width: 25px;
    height: 25px;
  }
  .Pending {
    width: 7px;
    height: 7px;
  }
}

@media (max-width: 1024px) and (min-width: 580px) {
  .Yes {
    width: 120px;
    height: 50px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  .No {
    width: 120px;
    height: 50px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  .InProgress {
    width: 120px;
    height: 50px;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
  }
  .QuestionNo {
    width: 28px;
    height: 28px;
  }
  .Pending {
    margin-top: 10px;
    width: 6px;
    height: 6px;
  }
}

.QuizButtonIcon {
  width: 18px;
  height: 18px;
}

.YouAnswered {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.AnsYes {
  font-weight: bold;
  color: #96e6a1;
}
.AnsNo {
  font-weight: bold;
  color: #fd578d;
}
.AnsPending {
  font-weight: bold;
  color: #8589d3;
}

.AnsSkipped {
  font-weight: bold;
  color: #ffffff;
}
