.Navigationbar {
  height: 14vh;
  margin-bottom: 14px;
}

.Child {
  height: 26vh;
}

.Logo {
  height: 40px;
  margin: 14px 14px 0 0;
  object-fit: contain;
}

.Message {
  float: right;
  height: 15px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.Name {
  float: right;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.Userdetail {
  margin-top: 15px;
}

.Account {
  height: 32px;
  //margin-left: -15px;
  object-fit: contain;
}

.BackSection {
  margin-top: 7px;
  display: inline-flex;
}

.Home {
  width: 22px;
  height: 22px;
  margin: 14px;
}

.Back {
  margin: 14px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
