.PaymentSegment {
  margin: 14px;
  width: 1205px;
  border-radius: 10px;
  background-color: #0a0c28;
}

.EnterMessage {
  margin: 14px;
  width: 165px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Sheild {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 0px 15px 0px 15px;
}

.SafeMoneyTransfer {
  width: 100%;
  height: 40px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Safe {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.ButtonText {
  width: 68px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Amount {
  width: 56px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9497c0;
}

.AmountWrapper {
  margin-top: 15px;
}

.USD {
  margin: 14px;
  width: 61px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.PaymentStatus {
  text-align: center;
  height: 23px;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
