.Number {
  font-family: Poppins;
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Text {
  font-family: Poppins;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.SubText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
}

.Reactangle {
  width: 176px;
  height: 5px;
  margin-top: 28px;
  margin-bottom: 28px;
  background-image: linear-gradient(to right, #6713d2, #cc208e);
}
