.Tick {
  width: 38px;
  cursor: pointer;
  height: 38px;
  object-fit: contain;
}

.Combinedshape {
  margin-top: -18px;
  margin-left: 7px;
}

.Oval {
  cursor: pointer;
  width: 31px;
  height: 31px;
  margin-top: -6px;
  margin-left: 1px;
  border-radius: 50%;
  border: solid 1.6px #7615c9;
}

.Nopaddingright {
  padding-right: 0 !important;
}

.Nopaddingleft {
  padding-left: 0 !important;
}
