.Welcome_to_AIRR {
  width: 344px;
  height: 56px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Welcome_to_AIRR .text_style {
  font-weight: bold;
}

.Start_an_Assessment {
  width: 573px;
  height: 56px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
}

.Welcome {
  width: 342px;
  height: 56px;
  font-family: Poppins;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Bold {
  font-weight: bold;
}

.AssessmentDetail {
  width: 573px;
  height: 56px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff;
}

.BlurText {
  padding-top: 40px;
  opacity: 0.15;
  font-family: Poppins;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.7;
  letter-spacing: normal;
  color: #ffffff;
  margin: 15px;
}

.Text {
  z-index: 100;
  margin-top: -35px;
  margin-left: 17px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #ffffff;
}

.Loading {
  height: 90vh;
  text-align: center;
}

.LoadingLogo {
  margin-top: 180px;
  width: 100px;
  /*height: 300px;*/
}

.EmailVerify {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #171941;
  text-align: center;
}

.Resend {
  cursor: pointer;
}
