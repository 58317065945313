$circle-length: 300px;
$check-length: 35px;

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawLine {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

#successAnimationCircle,
#failureAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: rgb(150, 230, 161);
}

#successAnimationCheck,
#failureAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: rgb(150, 230, 161);
}

#successAnimation.animated,
#failureAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;

  #successAnimationCircle,
  #failureAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle;
  }

  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck;
  }

  .failureAnimationCheckLine {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: drawLine 1s cubic-bezier(1, 0.2, 0.1, 1) forwards;
  }
}

#animate {
  background: rgba(255, 255, 255, 0.2);
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  bottom: 100px;
  color: rgb(150, 230, 161);
  left: 50%;
  outline: 0;
  padding: 10px 30px;
  position: absolute;
  transform: translateX(-50%);
  &:active {
    background: rgba(255, 255, 255, 0.1);
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: rgb(116, 116, 116);
  background: linear-gradient(to right, #ffffff 10%, #e9e3e3 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 10px;
  border-radius: 15px;
  position: relative;
  margin: 10px;
}

.animated-background-green {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: rgb(116, 116, 116);
  background: linear-gradient(to right, #a8ffb7 10%, #8cff81 18%, #bdec9d 33%);
  background-size: 800px 104px;
  height: 10px;
  border-radius: 15px;
  position: relative;
  margin: 10px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.css-yk16xz-control {
  border-color: unset !important;
  background-color: unset !important;
}

.css-rdyy6s-control {
  background-color: unset !important;
}

.css-1uccc91-singleValue {
  height: 50px !important;
  color: unset !important;
}

.css-g1d714-ValueContainer {
  height: 50px !important;
  font-size: 16px;
}

.css-1uccc91-singleValue {
  text-align: center;
  margin: 13px;
  font-size: 16px;
  color: hsla(0, 0%, 100%, 0.8);
}

.css-1fhf3k1-control {
  height: 50px !important;
  border-color: #2b3553 !important;
  background-color: #1d253b !important;
}

.css-b8ldur-Input {
  color: unset !important;
}

.lds-ring {
  margin-left: 7px;
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #007168 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-control {
  height: 50px;
  font-size: 16px;
}

.Show-password {
  width: 24px;
  height: 24px;
}

.modal-dialog {
  transform: translate(0) !important;
}

body::-webkit-scrollbar {
  width: 0.8em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}
