.Arrow {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.Active {
  background: #ffffff34;
  border: none;
  border-radius: 30px !important;
}
