.Logo {
  margin: 0 auto;
  object-fit: contain;
}
.Text1 {
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.Text2 {
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #9497c0;
}
.Start {
  width: 256px;
  height: 60px;
  border-radius: 6px;
  border: none;
  background-image: linear-gradient(277deg, #96e6a1, #d4fc79);
  &:hover {
    background-image: linear-gradient(277deg, #d4fc79, #96e6a1);
  }
}

.StartText {
  width: 162px;
  height: 28px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007168;
}

.Arrow {
  width: 30px;
  height: 20px;
  object-fit: contain;
}

.Image1 {
  float: left;
}

.Image2 {
  float: right;
}
.nomargin {
  margin-left: 0px;
  margin-right: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.Frame1 {
  border-radius: 40px 0 0 40px;
  background-color: #2e327e;
}

.Benefits1 {
  opacity: 0.1;
  font-family: Poppins;
  font-size: 100px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Benefits2 {
  z-index: 100;
  margin-top: -90px;
  margin-left: 5px;
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.BenifitsText {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}

.Services1 {
  opacity: 0.1;
  font-family: Poppins;
  font-size: 100px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Services2 {
  z-index: 100;
  margin-top: -90px;
  margin-left: 5px;
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.ServicesText {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}

.Blist {
  list-style-type: square;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}

.Frame2 {
  border-radius: 0 40px 40px 0;
  background-color: #2e327e;
}

.Solution1 {
  opacity: 0.1;
  font-family: Poppins;
  font-size: 100px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Solution2 {
  z-index: 100;
  margin-top: -90px;
  margin-left: 5px;
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Slist {
  list-style-type: square;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ffffff;
}

.WhiteFrame {
  margin-top: -150px;
  background-color: #ffffff;
}

.About1 {
  opacity: 0.1;
  font-family: Poppins;
  font-size: 100px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #171941;
}

.About2 {
  z-index: 100;
  margin-top: -90px;
  margin-left: 2px;
  font-family: Poppins;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #171941;
}

.AboutText {
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: justify;
  color: #0a0c28;
}

.Experience {
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Store {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.FooterLogo {
  width: 120px;
  height: 140px;
  margin-bottom: 28px;
  object-fit: contain;
}

.FooterIcons {
  margin-top: 7px;
  width: 24px;
  height: 28px;
}

.FooterText {
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.FooterTextCopyright {
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.AppStores {
  text-align: center;
  margin-bottom: 14px;
}

@media screen and (max-width: 580px) {
  .Benefits1 {
    font-size: 63px;
  }
  .Services1 {
    font-size: 63px;
  }
  .Solution1 {
    font-size: 63px;
  }
  .About1 {
    font-size: 63px;
  }
  .Image1 {
    height: 400px;
  }
}

.ContFlu {
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}
